@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

#root,
html,
body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  height: 100vh;
}
